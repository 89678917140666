//IMPORT ROUTER COMPONENTS
import Vue from 'vue'
import VueRouter from 'vue-router'

import SummaryDb from '../components/SummaryDb'

import AddMechine from '../components/mechine/AddMechine'
import AddMachineStatus from '../components/mechine/machine_status/AddMachineStatus'
import EditMachineStatus from '../components/mechine/machine_status/EditMachineStatus'
import MachineStatus from '../components/mechine/machine_status/MachineStatus'


import EditExtruder from '../components/mechine/edit_machines/EditExtruder'
import EditCompression from '../components/mechine/edit_machines/EditCompression'
import EditInjection from '../components/mechine/edit_machines/EditInjection'

import Extruder from '../components/mechine/Extruder'
import Corrugator from '../components/mechine/Corrugator'

import ExtruderConfig from '../components/mechine/machine_config/ExtruderConfig.vue'
import InjectionConfig from '../components/mechine/machine_config/InjectionConfig.vue'
import CompressionConfig from '../components/mechine/machine_config/CompressionConfig.vue'

import Injection from '../components/mechine/Injection'
import Compression from '../components/mechine/Compression'

import CompressionCharts from '../components/dashboard/machineCharts/CompressionCharts.vue'
import InjectionCharts from '../components/dashboard/machineCharts/InjectionCharts.vue'
import ExtruderCharts from '../components/dashboard/machineCharts/ExtruderCharts.vue'

import AddUser from '../components/auth/AddUser'
import Users from '../components/users/Users'
import EditViewUser from '../components/users/EditViewUser'

import DeviceList from '../components/devices/DeviceList'
import deviceComm from '../components/devices/deviceComm'

import Notification from '../components/devices/Notification'

import Products from '../components/products/Products'

import PassReset from '../components/auth/PassReset.vue'

import GateWayConf from '../components/gate_ways/GateWayConf.vue'

import AddProduct from '../components/products/AddProduct'
import EditProduct from '../components/products/EditProduct'

import Audit from '../components/audit_log/Audit'

import help from '../components/help'
//USER VUEROUTER
Vue.use(VueRouter)
//CREATE ROUTES
const router = new VueRouter({
   // mode: 'history',
    routes: [
        // {
        //     path: '/test', name: 'test', component: test
        // },
        {
            path: '/', name: 'SummaryDb', component: SummaryDb
        },
        {
            path: '/AddUser', name: 'AddUser', component: AddUser
        },
        {
            path: '/Users', name: 'Users', component: Users
        },
        {
            path: '/AddMechine', name: 'AddMechine', component: AddMechine
        },
        {
            path: '/DeviceList', name: 'DeviceList', component: DeviceList
        },
        {
            path: '/deviceComm/:DeviceId', name: 'deviceComm', component: deviceComm, props: true
        },
        {
            path: '/Notification', name: 'Notification', component: Notification
        },
        {
            path: '/Extruder', name: 'Extruder', component: Extruder
        },
        {
            path: '/Corrugator', name: 'Corrugator', component: Corrugator
        },
        {
            path: '/Injection', name: 'Injection', component: Injection
        },
        {
            path: '/Compression', name: 'Compression', component: Compression
        },
        {
            path: '/CompressionCharts/:machineName', name: 'CompressionCharts', component: CompressionCharts, props: true
        },
        {
            path: '/InjectionCharts/:machineName', name: 'InjectionCharts', component: InjectionCharts, props: true
        },
        {
            path: '/ExtruderCharts/:machineName', name: 'ExtruderCharts', component: ExtruderCharts, props: true
        },
        {
            path: '/EditViewUser', name: 'EditViewUser', component: EditViewUser, props: true
        },
        {
            path: '/EditExtruder', name: 'EditExtruder', component: EditExtruder, props: true
        },
        {
            path: '/EditCompression', name: 'EditCompression', component: EditCompression, props: true
        },
        {
            path: '/EditInjection', name: 'EditInjection', component: EditInjection, props: true
        },
        {
            path: '/ExtruderConfig', name: 'ExtruderConfig', component: ExtruderConfig, props: true
        },
        {
            path: '/Products', name: 'Products', component: Products
        },
        {
            path: '/CompressionConfig', name: 'CompressionConfig', component: CompressionConfig, props: true
        },
        {
            path: '/InjectionConfig', name: 'InjectionConfig', component: InjectionConfig, props: true
        },
        {
            path: '/PassReset:key', name: 'PassReset', component: PassReset,
        },
        {
            path: '/GateWayConf/:id', name: 'GateWayConf', component: GateWayConf, props: true
        },
        {
            path: '/AddProduct', name: 'AddProduct', component: AddProduct, 
        },
        {
            path: '/EditProduct', name: 'EditProduct', component: EditProduct, props: true 
        },
        {
            path: '/Audit', name: 'Audit', component: Audit 
        },
        {
            path: '/AddMachineStatus', name: 'AddMachineStatus', component: AddMachineStatus
        },
        {
            path: '/EditMachineStatus', name: 'EditMachineStatus', component: EditMachineStatus, props: true 
        },
        {
            path: '/MachineStatus', name: 'MachineStatus', component: MachineStatus
        },
        {
            path: '/help', name: 'help', component: help,
        }
    ]
  })
  export default router