<template>
    <div class="addUser bg-dark">
      <div class="form">
      <form class="form" v-for="gate in gateWay" :key="gate.gate_way_id">
         <div class="form-group">
            <label for="fullname">Name:</label>
            <input type="fullname" class="form-control" placeholder="" disabled id="fullname" v-model="gate.gateway_name">
        </div>
         <div class="form-group">
            <label for="fullname">Description:</label>
            <input type="fullname" class="form-control" placeholder="" disabled id="fullname" v-model="gate.description">
        </div>
         <div class="form-group">
            <label for="date">Gate Way ID:</label>
            <input type="text" class="form-control" placeholder="" disabled id="email" v-model="gate.gate_way_id">
        </div>
        <div class="form-group">
            <label for="status">Status:</label>
              <select class="form-control" id="status" v-model="gate.status">
              <option v-for="state in status" :key="state.state">{{ state }}</option>
            </select>
         </div>
      <button  class="btn-sm btn-success" type="button" @click="config()">Submit</button>
      <router-link tag="button" to="/" class="btn-sm btn-danger">Back</router-link>
    </form>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        gate_way: {
            name: '',
            lastSeenAt: '',
            createdAt: '',
            description: '',
            id: ''
        }
    },
    data() {
      return {
        gateWay: [],
        userType: '',
        status: [
          'ON',
          'OFF'
        ]
      }
    },

    mounted() {
          this.getWayDetails()
    },

    methods: {
     //GET GATEWAY DETAILS  
      getWayDetails() {
        let gateWayId = this.$route.params.id
        let api_function = "gateWayDetails"
        // console.log(gateWayId)
        axios.get('https://m4a.ramacsystems.com/backend/GateWays/gateWay.php?params=', {
          params: {
            gateWayId: gateWayId,
            api_function: api_function
          }
        })
        .then((res) => {
          this.gateWay = res.data 
          // console.log(res.data)
        })
      },

      config() {
        let gateWayId = this.$route.params.id
        let status = document.getElementById('status').value
        let formData = new FormData();
        formData.append('gateWayId', gateWayId)
        formData.append('status', status)
       axios({
         url: 'https://m4a.ramacsystems.com/backend/GateWays/gateWay.php',
         method: 'post',
         data: formData,
         config: { headers: { 'Content-Type' : 'multipart/formdata'}},
       })
       .then((res) => {
          console.log(status, gateWayId)
          console.log(res.data)
        })
      .catch(function (error) {
         console.log(error)
        })
      },
        //GET USER DETAILS
      getUserDetails() {
        let userDetails = localStorage.getItem('userType')
        this.userType = userDetails
      },
    }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>